//Colours:
$c-blue: #143a5e;
$c-black: #000000;
$c-white: #ffffff;

//Mixin and responsive levels
$desktop: 1280px;
$tablet-lg: 992px;
$mobile-lg: 660px;
$height-lg: 768px;


@mixin tablet-lg {
	@media (max-width: $tablet-lg) {
		@content;
	}
}

@mixin mobile-lg {
	@media (max-width: $mobile-lg) {
		@content;
	}
}

@mixin height-lg {
	@media (max-height: $height-lg ) {
		@content;
	}
}


//Styling
.App{
	text-align: center;
	@include tablet-lg {
		display: flex;
		flex-direction: column;
	}
}

html {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

*, *:before, *:after {
	-webkit-box-sizing: inherit;
	-moz-box-sizing: inherit;
	box-sizing: inherit;
}

html, body {
	margin: 0 auto;
	padding: 0px;
}

*{
	font-family: "Helvetica Neue", Arial;
}

*::-webkit-scrollbar{
display: none;
}

.col- {
	&full {
		width: 100%;
	}
	&half {
		font-family: "Helvetica Neue", Arial;
		font-weight: 400;
		width: 50%;
		padding: 50px;
		text-align: left;
		font-size: 30px;
		color: $c-blue;
		@include tablet-lg {
			padding: 30px;
		}
		@include mobile-lg {
			width: auto;
			padding: 10px;
		}
	}
}

.width50 {
	width: 50%;
	@include mobile-lg {
		width: 100%;
	}
}

.container-{
	&content {
		max-width: $desktop;
		margin: 0 auto;
		display: flex;
		@include mobile-lg {
			border: none;
			margin: 15px auto;
			display: block;
			padding: 0 15px;
		}
	}
	&footer {
		background-color: $c-blue;
		display: flex;
		justify-content: space-between;
		min-height: 50px;
		padding: 5px 20px 0 20px;
		position:fixed;
		bottom:0px;
		width:100%;
		@include mobile-lg {
			position: initial;
			display: block;
			padding: 10px 10px 0 10px;
		}
		@include height-lg {
			position: static;
		}
	}
	&topbar {
		margin: 0 auto 3% auto;
		width: 100%;
		background: url("assets/header.jpg") no-repeat center center;
		background-size: cover;
	}
	&register {
		padding: 10px 0px;
	}
}

.footer- {
	&address {
		color: $c-white;
		text-align: start;
		margin: auto 20px;
		font-size: 14px;
		font-weight: 400;
		font-family: "Helvetica Neue", Arial;
		@include mobile-lg {
			text-align: center;
			margin: auto;
			font-size: 12px;
		}
	}
	&images {
		display: flex;
		justify-content: flex-end;
		margin: 0px 20px;
		@include mobile-lg {
			margin: 10px auto 0 auto;
			justify-content: center;
		}
	}
}

.logo-{
	&footer {
		&-wave {
			max-height: 45px;
			padding-top: 11px;
			margin-right: 20px;
			opacity: 0.5;
			@include mobile-lg {
				margin-right: 10px;
			}
		}
		&-text {
			max-height: 30px;
			margin: auto 0;
			@include mobile-lg {
				max-height: 20px;
			}
		}
	}
	&desktop {
		max-height: 240px;
		margin: 22px 0;
		@include mobile-lg {
			display: none;
		}
	}
	&mobile {
		display: none;
		@include mobile-lg {
			margin: 0px auto 15px auto;
			max-height: 150px;
			display: block;
		}
	}
}

.body-{
	&copy {
		margin: 15px 0;
		font-size: 16px;
		line-height: 1.3;
		font-weight: 200;
		font-family: "Helvetica Neue", Arial;
		color: $c-black;
		letter-spacing: 0.4px;
	}
	&post {
		&-title {
			color: $c-blue;
			font-size: 18px;
			font-family: "Helvetica Neue", Arial;
			font-weight: 500;
			margin-top: 15px;

		}
		&-info {
			color: $c-black;
			font-size: 16px;
			font-family: "Helvetica Neue", Arial;
			font-weight: 200;
		}
		&-span {
			font-weight: 400;
			font-family: "Helvetica Neue", Arial;
		}
		&-form {
			margin: 15px 0px;
			display: flex;
			justify-content: space-between;
		}
	}
}

.form- {
	&input {
		&-top {
			width:100%;
			display: flex;
			justify-content: space-between;
			@include tablet-lg {
				display: block;
			}
		}
		&-first {
			border: 1px solid black;
			width: 45%;
			margin: 5px 0px;
			font-size: 14px;
			padding: 5px 0 5px 5px;
			&.true {
				box-shadow: 0 0 5px #dd2222;
				border: 1px solid #dd2222;
			}
			@include tablet-lg {
				width: 100%;
			}
		}
		&-last {
			border: 1px solid black;
			width: 45%;
			margin: 5px 0px;
			font-size: 14px;
			padding: 5px 0 5px 5px;
			&.true {
				box-shadow: 0 0 5px #dd2222;
				border: 1px solid #dd2222;
			}
			@include tablet-lg {
				width: 100%;
			}
		}
		&-phone {
			border: 1px solid black;
			width: 45%;
			margin: 5px 0px;
			font-size: 14px;
			padding: 5px 0 5px 5px;
			&.true {
				box-shadow: 0 0 5px #dd2222;
				border: 1px solid #dd2222;
			}
			@include tablet-lg {
				width: 100%;
			}
		}
		&-email {
			border: 1px solid black;
			width: 99%;
			margin: 5px 0px;
			font-size: 14px;
			padding: 5px 0 5px 5px;
			&.true {
				box-shadow: 0 0 5px #dd2222;
				border: 1px solid #dd2222;
			}
			@include tablet-lg {
				width: 100%;
			}
		}
		&-comment {
			border: 1px solid black;
			width: 99% !important;
			resize: none;
			margin: 10px 0px;
			font-size: 14px;
			padding: 5px 0 20px 5px;
			@include tablet-lg {
				width: 100% !important;
			}
		}
	}
	&submit {
		&-button {
			height: 40px;
			font-family: "Helvetica Neue", Arial;
			width: 120px;
			background-color: $c-blue;
			color: $c-white;
			font-size: 18px;
			&:focus {
				outline: none;
			}
		}
	}
	&post-form{
		float: right;
	}
	&register {
		margin-bottom: 40px;
	}
	&success {
		display: none;
		text-align: center;
		font-size: 18px;
		font-family: "Helvetica Neue", Arial;
		font-weight: 400;
		padding: 15px;
		line-height: 1.5;
		border-radius: 5px;
		margin-top: 35px;
	}

}

.border-line {
	border-right: 1px solid black;
	margin: 4% 0;
	@include mobile-lg {
		display: none;
	}
}

.breaker {
	display: none;
	@media (max-width: 1035px) {
		display: block;
	}
	@include mobile-lg {
		display: block;
	}
}

